import * as Modal from '@radix-ui/react-dialog'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef
} from 'react'

import { cn } from '@Shared/helpers/util'

export type HeaderProps = ComponentProps<'div'>
export type TicketProps = ComponentProps<'div'>

const Close = forwardRef<
  ElementRef<typeof Modal.Close>,
  ComponentPropsWithoutRef<typeof Modal.Close>
>(({ children, className, ...props }, ref) => (
  <Modal.Close ref={ref} {...props}>
    {children}
  </Modal.Close>
))
Close.displayName = Modal.Close.displayName

const Content = forwardRef<
  ElementRef<typeof Modal.Content>,
  ComponentPropsWithoutRef<typeof Modal.Content>
>(({ children, className, ...props }, ref) => (
  <div className="ing-scrollbar">
    <Modal.Content
      ref={ref}
      className={cn(
        'fixed left-1/2 top-1/2 z-[99] max-h-[85vh] w-[270px] -translate-x-1/2 -translate-y-1/2 rounded-xl lg:w-[450px]',
        className
      )}
      {...props}
    >
      {children}
    </Modal.Content>
  </div>
))
Content.displayName = Modal.Content.displayName

const Ticket = ({ children, className }: TicketProps) => (
  <div
    className={cn(
      'before:bg-red relative -mt-8 overflow-hidden before:absolute before:left-[calc(50%_-_32px)] before:top-0 before:mx-auto before:-mt-8 before:box-border before:block before:h-16 before:w-16 before:overflow-hidden before:rounded-full before:bg-transparent before:shadow-[0_0_0_999px_#21262d] before:content-[""]',
      className
    )}
  >
    <div className="relative z-10 flex flex-col gap-3 px-8 py-8">
      {children}
    </div>
  </div>
)

const Header = ({ children, className }: HeaderProps) => (
  <div
    className={cn(
      'relative flex h-28 items-baseline justify-center bg-ing-neutral-800 md:items-center lg:h-[150px]',
      className
    )}
  >
    {children}
  </div>
)

const Title = forwardRef<
  ElementRef<typeof Modal.Title>,
  ComponentPropsWithoutRef<typeof Modal.Title>
>(({ children, className, ...props }, ref) => (
  <Modal.Title ref={ref} className={cn('text-white', className)} {...props}>
    {children}
  </Modal.Title>
))
Title.displayName = Modal.Title.displayName

const Overlay = forwardRef<
  ElementRef<typeof Modal.Overlay>,
  ComponentPropsWithoutRef<typeof Modal.Overlay>
>(({ className, ...props }, ref) => (
  <Modal.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-[98] h-full w-full bg-[#191919]/80',
      className
    )}
    {...props}
  />
))
Overlay.displayName = Modal.Overlay.displayName

const Dialog = {
  Root: Modal.Root,
  Portal: Modal.Portal,
  Title,
  Ticket,
  Trigger: Modal.Trigger,
  Overlay,
  Content,
  Header,
  Description: Modal.Description,
  Close: Modal.Close
}

export default Dialog
