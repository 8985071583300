import { socialMedia } from '@Shared/constants/footer'
import { eventCategory } from '@Shared/helpers/tracking'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Icon from '@Components/icon'
import Link from '@Components/Link'

const SocialMedia = () => {
  const pageName = usePageName()

  const socialTracking = (label: string) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on Footer Social Media`,
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <ul className="flex items-start gap-2 lg:mt-[10px] lg:flex-col">
      {socialMedia.map((item) => (
        <li
          className="text-sm leading-4 lg:mt-2"
          key={item.label}
          onClick={() => {
            socialTracking(item.label)
          }}
        >
          <Link
            className="flex items-center text-white no-underline lg:text-ing-blue-400"
            href={item.url}
          >
            <Icon
              className="lg:mr-[6px] [&_path]:fill-ing-blue-400 lg:[&_path]:fill-white [&_svg]:mr-2 [&_svg]:h-[14px] [&_svg]:w-[14px]"
              src={`/images/social-media/${item.label?.toLowerCase()}.svg`}
              alt={`Ícone do ${item.label} da ingresso.com`}
            />

            <span className="text-[11px] lg:text-sm lg:leading-4">
              {item.label}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default SocialMedia
