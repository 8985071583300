import { useMedia } from 'react-use'

import { helpLinks, cancelOrder } from '@Shared/constants/footer'
import { eventCategory } from '@Shared/helpers/tracking'
import useIsClient from '@Shared/hooks/use-is-client'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

import SupportBox from '../support-box-links'

const cancelOrderIndex = 1

const Help = () => {
  const pageName = usePageName()
  const { isClient } = useIsClient()
  const isMobile = useMedia('(max-width: 768px)')

  isClient &&
    !isMobile &&
    helpLinks[cancelOrderIndex].label !== cancelOrder.label &&
    helpLinks.splice(cancelOrderIndex, 0, cancelOrder)

  const helpTracking = (label: string) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on Footer Help`,
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  const Content = () => (
    <ul className="flex columns-2 gap-6 md:block md:gap-5 lg:mt-4 lg:columns-1">
      {helpLinks.map((item) => (
        <li
          className="mb-4 text-[11px] md:mb-2 lg:text-sm lg:leading-4"
          key={item.label}
          onClick={() => {
            helpTracking(item.label)
          }}
        >
          <Link
            className="flex items-center text-ing-blue-400 no-underline"
            href={item.url}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )

  return isMobile ? (
    <SupportBox>
      <Content />
    </SupportBox>
  ) : (
    <Content />
  )
}

export default Help
