import React, { useState } from 'react'
import { useMedia } from 'react-use'

import { eventCategory } from '@Shared/helpers/tracking'
import useIsClient from '@Shared/hooks/use-is-client'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

import Help from './help-links'
import Institutional from './institutional-links'
import Policies from './policies-links'
import SocialMedia from './social-media-links'

const linkList = [
  {
    title: 'Institucional',
    label: 'institutional',
    component: <Institutional />
  },
  {
    title: 'Políticas',
    label: 'policies',
    component: <Policies />
  },
  {
    title: 'Redes Sociais',
    label: 'social',
    component: <SocialMedia />
  },
  {
    title: 'Ajuda',
    label: 'help',
    component: <Help />
  }
]

const LinkArea = () => {
  const pageName = usePageName()
  const { isClient } = useIsClient()
  const [currentDialog, setCurrentDialog] = useState('')
  const isMobile = useMedia('(max-width: 991px)')

  const handleDialog = (current) => {
    setCurrentDialog(current === currentDialog ? '' : current)
  }

  const menuTracking = (label: string) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on Footer Menu`,
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <>
      {isClient && (
        <div className="border-b border-solid border-ing-neutral-600 pb-4 lg:flex-1 lg:border-none lg:pb-0">
          <ul className="flex justify-between gap-6 pt-4 lg:px-4 lg:py-10">
            {!isMobile && (
              <li>
                <strong>Menu</strong>
                <ul className="lg:mt-4 [&_li]:mb-2 [&_li]:text-sm [&_li]:leading-4">
                  <li
                    onClick={() => {
                      menuTracking('Movies')
                    }}
                  >
                    <Link
                      className="flex items-center text-ing-blue-400 no-underline"
                      href="/filmes"
                    >
                      Filmes
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      menuTracking('Theaters')
                    }}
                  >
                    <Link
                      className="flex items-center text-ing-blue-400 no-underline"
                      href="/cinemas"
                    >
                      Cinemas
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {linkList.map((item) => (
              <li
                key={item.label}
                className={`relative ${
                  currentDialog === item.label
                    ? "after:absolute after:left-1/2 after:-ml-4 after:mt-[6px] after:block after:border-x-[15px] after:border-b-[15px] after:border-t-0 after:border-solid after:border-ing-neutral-600 after:border-x-transparent after:content-[''] after:lg:hidden"
                    : ''
                }`}
              >
                {isMobile ? (
                  <span
                    className="cursor-pointer text-[11px] text-ing-blue-400 no-underline"
                    onClick={() => handleDialog(item.label)}
                  >
                    {item.title}
                  </span>
                ) : (
                  <>
                    <strong>
                      {item.title === 'Ajuda'
                        ? 'Precisa de Ajuda?'
                        : item.title}
                    </strong>
                    {item.component}
                  </>
                )}
              </li>
            ))}
          </ul>

          {currentDialog && (
            <div className="-mx-4 mb-0 mt-4 list-none bg-ing-neutral-600 p-4 lg:hidden">
              {linkList.map(
                (item) =>
                  item.label === currentDialog && (
                    <React.Fragment key={item.label}>
                      {item.component}
                    </React.Fragment>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LinkArea
